<!-- 设置页 -->
<template>
  <div class="setUp">
    <!-- tab -->
    <div>
      <HeaderTab :tabData="tabData" />
    </div>
    <!-- 数据上报工作流 -->
    <div class="box_item">
      <h2 class="title">数据采集上报工作流</h2>
      <Steps :list="list" style="margin: 40px 0 0;" />
    </div>
    <!-- 数据采集设置 -->
    <div class="box_item">
      <h2 class="title">数据采集设置</h2>
      <div class="write">
        <div class="h3">自动采集:</div>
        <div class="radio">
          <a-radio-group name="radioGroup" :default-value="1">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="2">
              关闭
            </a-radio>
          </a-radio-group>
        </div>
        <div>自动采集时间设置：</div>
        <a-select
          class="select"
          v-model="listQuery.type1"
          style="width:200px"
          mode="default"
          placeholder="请选择"
          :default-value="[]"
          @change="handleChange(item, $event)"
        >
          <a-select-option
            v-for="(i, index) in option"
            :key="index"
            :value="i.id"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary">
          保存
        </a-button>
      </div>
    </div>
    <!-- 数据上报设置 -->
    <div class="box_item">
      <h2 class="title">数据上报设置</h2>
      <div class="write">
        <div class="h3">自动采集:</div>
        <div class="radio">
          <a-radio-group name="radioGroup" :default-value="1">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="2">
              关闭
            </a-radio>
          </a-radio-group>
        </div>
        <div>自动采集时间设置：</div>
        <a-select
          class="select"
          v-model="listQuery.type2"
          style="width:200px"
          mode="default"
          placeholder="请选择"
          :default-value="[]"
          @change="handleChange(item, $event)"
        >
          <a-select-option
            v-for="(i, index) in option"
            :key="index"
            :value="i.id"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary">
          保存
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTab from "@/components/tab/headerTab/index.vue";
import Steps from "@/components/steps/StepsTwoView.vue";
export default {
  name: "SetUp",
  components: {
    HeaderTab,
    Steps,
  },
  props: {},
  data() {
    return {
      tabData: [
        { id: 0, title: "数据采集上报设置" },
        { id: 1, title: "角色权限" },
        { id: 2, title: "权限管理" },
      ],
      list: [
        { name: "院内各业务系统", stepName: "数据采集" },
        { name: "门店数据库", stepName: "数据展示" },
        { name: "院内门店平台", stepName: "数据上报" },
        { name: "国家门店平台", stepName: "" },
      ],
      listQuery: {
        type1: "",
        type2: "",
      },
      option: [
        { id: 1, name: "每日0时及13时自动采集" },
        { id: 2, name: "患者出院后第5天" },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.setUp {
  background-color: #fff;
  border-radius: 5px;
}
.box_item {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 20px 40px;
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #262626;
  }

  .write {
    display: flex;
    align-items: center;
    .h3,
    .radio {
      margin-right: 20px;
    }
    .select {
      margin-right: 40px;
    }
  }
}
</style>
