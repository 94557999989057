<!-- 
    定制化步骤条,传入
    list -步骤数组
    activeIndex-当前高亮步骤index
-->
<template>
  <div class="list">
    <div class="list_one" v-for="(item, index) in list" :key="index">
      <div style="position: relative;">
        <div :class="{ active: activeIndex >= index }" class="num">
          {{ index + 1 }}
        </div>
        <h3 class="name1">
          {{ item.name }}
        </h3>
      </div>
      <div style="position: relative;">
        <div
          class="line"
          :class="{ lineActive: activeIndex - 1 >= index }"
          v-if="list.length - 1 > index"
        ></div>
        <h4 class="name2">
          {{ item.stepName }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeIndex: {
      type: Number,
    },
    list: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="less">
.list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  // border: 1px solid red;
  padding: 0 40px 30px;
}
.list_one {
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  .num {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    background: #1d6aff;
    color: #fff;
  }
  .line {
    height: 1px;
    width: 200px;
    background: #d9d9d9;
  }
  .name1 {
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    font-size: 15px;
    position: absolute;
    width: 200px;
    left: -35px;
    top: 30px;
  }
  .name2 {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    position: absolute;
    left: 60px;
    bottom: 0px;
  }
}
.active {
  background: #1d6aff !important;
}
.lineActive {
  background: #999999 !important;
}
</style>
